import React from "react";

const SubTitle = (props) => {
  return (
    <div className="mx-auto max-w-xs">
      <h2 className="text-2xl text-center border-b-2 pb-2 border-gray-800 font-sans tracking-widest font-medium text-gray-800">
        {props.string}
      </h2>
    </div>
  );
};

export default SubTitle;

import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const Staff = (props) => {
  const md = props.id % 2 === 0 ? "md:bg-royalblue-100" : "md:bg-transparent";
  const sm = Math.floor(props.id / 2) % 2 === 1 ? "bg-royalblue-100" : "";

  const handleClick = () => {
    props.openModal(props);
  };

  return (
    <div
      className={`p-5 ${md} ${sm}`}
      onClick={handleClick}
      onKeyDown={handleClick}
      role="button"
      tabIndex={0}
    >
      <div className="text-center">{props.img}</div>
      <div className="text-center mt-3 font-sans text-sm md:text-base">
        {props.name}
      </div>
    </div>
  );
};

export default Staff;

export const staffs = [
  {
    id: 0,
    name: "鈴木 正治",
    img: (
      <StaticImage
        src="../images/staffs/m-suzuki.jpg"
        alt="Suzuki Masaharu"
        className="rounded-full shadow-md hover:shadow-inner z-10"
        width={200}
        height={200}
      />
    ),
    position: "所長",
    license: "税理士・行政書士",
    interests: "サッカー観戦・中国語",
    food: "麻辛火鍋",
    gunma: "アウトドアの充実と温泉",
    message: "良き相談相手を心がけ、成長をサポートさせて頂きます！",
  },
  {
    id: 1,
    name: "小池 真澄",
    img: (
      <StaticImage
        src="../images/staffs/koike.jpg"
        alt="koike"
        className="rounded-full shadow-md hover:shadow-inner z-10"
        width={200}
        height={200}
      />
    ),
    position: "資産税部",
    license: "税理士",
    interests: "ワンコと一緒の旅行",
    food: "麦・米・芋の…",
    gunma: "かかあ天下",
    message: "親身になって対応させて頂きます",
  },
  {
    id: 2,
    name: "鈴木 正光",
    img: (
      <StaticImage
        src="../images/staffs/mm-suzuki.jpg"
        alt="mm-suzuki"
        className="rounded-full shadow-md hover:shadow-inner z-10"
        width={200}
        height={200}
      />
    ),
    position: "会長",
    license: "税理士・社会保険労務士・行政書士",

    interests: "海外旅行",
    food: "漬物",
    gunma: "赤城山",
    message: "家と企業の永続繁栄を願い支援をいたします。",
  },
  {
    id: 3,
    name: "大谷 敏明",
    img: (
      <StaticImage
        src="../images/staffs/otani.jpg"
        alt="otani"
        className="rounded-full shadow-md hover:shadow-inner z-10"
        width={200}
        height={200}
      />
    ),
    position: "監査第１Gリーダー",
    interests: "映画鑑賞",
    food: "お鮨",
    gunma: "敷島公園（バラ園）",
    message: "お客様発展の為全力を投入します。",
  },
  {
    id: 4,
    name: "菊池 賢三",
    img: (
      <StaticImage
        src="../images/staffs/kikuchi.jpg"
        alt="kikuchi"
        className="rounded-full shadow-md hover:shadow-inner z-10"
        width={200}
        height={200}
      />
    ),
    position: "監査第１G",
    interests: "旅行",
    food: "カレーライス",
    gunma: "関東の中心（温泉）",
    message: "お客様のために支援します。",
  },
  {
    id: 5,
    name: "三友 俊幸",
    img: (
      <StaticImage
        src="../images/staffs/mitsutomo.jpg"
        alt="mitsutomo"
        className="rounded-full shadow-md hover:shadow-inner z-10"
        width={200}
        height={200}
      />
    ),
    position: "監査第１G",
    interests: "旅",
    food: "プロテイン",
    gunma: "都心からの程よい距離感",
    message: "がんばるべー そうだんべー",
  },
  {
    id: 6,
    name: "西山 則人",
    img: (
      <StaticImage
        src="../images/staffs/nishiyama.jpg"
        alt="nishiyama"
        className="rounded-full shadow-md hover:shadow-inner z-10"
        width={200}
        height={200}
      />
    ),
    position: "監査部部長　監査第２Gリーダー",
    interests: "野球雑学",
    food: "体調悪い時はカレー",
    gunma: "起立！注目！礼！着席！",
    message: "良いフォロワーと思ってもらえるように",
  },
  {
    id: 7,
    name: "木暮 謙吾",
    img: (
      <StaticImage
        src="../images/staffs/kogure.jpg"
        alt="kogure"
        className="rounded-full shadow-md hover:shadow-inner z-10"
        width={200}
        height={200}
      />
    ),
    position: "監査第２G",
    interests: "野球鑑賞（結果だけ）",
    food: "ラーメンはみそ、フリアンはミックス",
    gunma: "海がない（魚介嫌い）",
    message: "いつもお世話になっている分しっかりとフォロー致します",
  },
  {
    id: 8,
    name: "大嶋 きよみ",
    img: (
      <StaticImage
        src="../images/staffs/oshima.jpg"
        alt="oshima"
        className="rounded-full shadow-md hover:shadow-inner z-10"
        width={200}
        height={200}
      />
    ),
    position: "監査第２G",
    interests: "ﾗｲﾌﾞ･旅･ﾎﾞﾗﾝﾃｨｱ活動",
    food: "スイーツ・フルーツ",
    gunma: "(位置は)日本の真ん中",
    message: "行動力、体力、ﾁｰﾑﾜｰｸで使命感を持って対応します。",
  },
  {
    id: 9,
    name: "下田 宏幸",
    img: (
      <StaticImage
        src="../images/staffs/shimoda.jpg"
        alt="shimoda"
        className="rounded-full shadow-md hover:shadow-inner z-10"
        width={200}
        height={200}
      />
    ),
    position: "監査第２G",
    interests: "80年代音楽（ごく狭い範囲）",
    food: "黄金色の麦茶",
    gunma: "災害が少ない",
    message: "お客様に寄り添ってまいります。",
  },
  {
    id: 10,
    name: "狩野 智春",
    img: (
      <StaticImage
        src="../images/staffs/kanou.jpg"
        alt="kanou"
        className="rounded-full shadow-md hover:shadow-inner z-10"
        width={200}
        height={200}
      />
    ),
    position: "中央労務管理事務所",
    license: "社会保険労務士",
    interests: "本屋巡り",
    food: "チーズ以外",
    gunma: "温泉",
    message: "お困り事にしっかりと対応させていただきす。",
  },
  {
    id: 11,
    name: "摩庭 千恵",
    img: (
      <StaticImage
        src="../images/staffs/maniwa.jpg"
        alt="maniwa"
        className="rounded-full shadow-md hover:shadow-inner z-10"
        width={200}
        height={200}
      />
    ),
    position: "中央労務管理事務所",
    interests: "海外国内旅行・ﾄﾚｯｷﾝｸﾞ・英語",
    food: "うなぎ以外",
    gunma: "水と野菜と果物がおいしい",
    message: "縁の下の力持ち的存在でサポートさせて頂きます。",
  },
  {
    id: 12,
    name: "関口 留美",
    img: (
      <StaticImage
        src="../images/staffs/sekiguchi.jpg"
        alt="sekiguchi"
        className="rounded-full shadow-md hover:shadow-inner z-10"
        width={200}
        height={200}
      />
    ),
    interests: "菓子作り",
    food: "パスタ",
    gunma: "温泉・ゴルフお手頃",
    message: "来社時に快い応対をさせていただきます。",
  },
  {
    id: 13,
    name: "西田 明美",
    img: (
      <StaticImage
        src="../images/staffs/nishida.jpg"
        alt="nishida"
        className="rounded-full shadow-md hover:shadow-inner z-10"
        width={200}
        height={200}
      />
    ),
    interests: "昼寝",
    food: "甘い物",
    gunma: "空気がおいしい",
    message: "明るい電話応対心がけます。",
  },
];

import React from "react";

const Section = (props) => {
  const bg = props.bg ? "bg-steel-50" : "";

  return (
    <section className={bg}>
      <div className="container mx-auto max-w-4xl px-5 md:px-10 py-10 md:py-20 text-lg md:text-xl space-y-5 md:space-y-10">
        {props.children}
      </div>
    </section>
  );
};

export default Section;

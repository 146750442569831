export const services = [
  {
    id: 1,
    name: "会計・税務",
    descriptions: [
      "月次訪問による巡回監査業務",
      "経理業務の効率化支援（属人化からの脱却）",
      "決算書・申告書の作成",
      "税務相談・経営助言",
    ],
  },
  {
    id: 2,
    name: "資金繰り",
    descriptions: ["キャッシュ・フローの見直し支援", "銀行融資時の支援"],
  },
  { id: 3, name: "事業承継", descriptions: ["承継計画作成支援・株式評価"] },
  {
    id: 4,
    name: "内部統制",
    descriptions: ["業務フロー・社内各種規程作成支援"],
  },
  {
    id: 5,
    name: "相続・贈与",
    descriptions: ["将来の相続に備えた準備支援", "相続税・贈与税申告書の作成"],
  },
  { id: 6, name: "保険指導", descriptions: ["万が一の時に備えた企業防衛支援"] },
  { id: 7, name: "補助金・助成金", descriptions: ["申請支援"] },
  {
    id: 8,
    name: "創業支援",
    descriptions: [
      "創業計画策定サポート",
      "公的資金活用支援",
      "創業後の経営支援　",
      "登記・届出",
    ],
  },
  { id: 9, name: "M＆A", descriptions: ["外部機関と連携したM＆A支援"] },
  {
    id: 10,
    name: "海外展開",
    descriptions: ["中国・アジアを中心とした国への進出支援、組織再編サポート"],
  },
];

import React from "react";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import MobileVideo from "./mobileVideo";
import DesktopVideo from "./desktopVideo";

const Hero = () => {
  const breakpoints = useBreakpoint();
  return (
    <div className="flex justify-center items-center">
      <div className="absolute leading-10 md:leading-video-title xl:leading-video-title text-xl md:text-2xl xl:text-3xl text-white bg-opacity-30 bg-steel-500 p-5 md:px-5 lg:px-20 lg:py-10">
        おかげさまで50年。
        <br />
        鈴木会計事務所は「家と企業の永続繁栄」の理念のもと、
        <br />
        全力でお客様に寄り添い、
        <br />
        経営に役立つお手伝いをします。
      </div>
      {breakpoints.md ? <MobileVideo /> : <DesktopVideo />}
    </div>
  );
};

export default Hero;

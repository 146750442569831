import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment } from "react";

export default function Modal(props) {
  return (
    <Transition appear show={props.isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={props.closeModal}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            //enterFrom="opacity-0"
            //enterTo="opacity-100"
            leave="ease-in duration-200"
            //leaveFrom="opacity-100"
            //leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-steel-800 opacity-30" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-md p-5 md:p-10 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-md">
              <div className="flex flex-col space-y-3">
                <div className="flex justify-center mb-3">
                  {props.staffProps.img}
                </div>
                <div className="flex items-baseline">
                  <div className="w-2/5 text-sm">名前</div>
                  <div className="w-3/5">{props.staffProps.name}</div>
                </div>
                {props.staffProps.position && (
                  <div className="flex items-baseline">
                    <div className="w-2/5 text-sm">役職</div>
                    <div className="w-3/5">{props.staffProps.position}</div>
                  </div>
                )}
                {props.staffProps.license && (
                  <div className="flex items-baseline">
                    <div className="w-2/5 text-sm">保有資格</div>
                    <div className="w-3/5">{props.staffProps.license}</div>
                  </div>
                )}
                <div className="flex items-baseline">
                  <div className="w-2/5 text-sm">趣味・特技</div>
                  <div className="w-3/5">{props.staffProps.interests}</div>
                </div>
                <div className="flex items-baseline">
                  <div className="w-2/5 text-sm">好きな食べ物</div>
                  <div className="w-3/5">{props.staffProps.food}</div>
                </div>
                <div className="flex items-baseline">
                  <div className="w-2/5 text-sm">群馬のよいところ</div>
                  <div className="w-3/5">{props.staffProps.gunma}</div>
                </div>
                <div className="flex items-baseline">
                  <div className="w-2/5 text-sm">お客様へ一言</div>
                  <div className="w-3/5">{props.staffProps.message}</div>
                </div>
              </div>
              <div className="mt-5 md:mt-10 flex justify-center">
                <button
                  type="button"
                  className="inline-flex justify-center px-4 py-2 text-sm font-medium text-steel-900 bg-steel-100 border border-transparent rounded-sm hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-steel-500"
                  onClick={props.closeModal}
                >
                  閉じる
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}

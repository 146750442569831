import React from "react";
import Poster from "../images/building_lg.webp";
import src from "../images/suzuki-office.mp4";

const DesktopVideo = () => {
  return (
    <video
      autoPlay
      muted
      loop
      playsInline
      poster={Poster}
      className="md:w-full h-96 md:h-auto object-cover"
    >
      <source src={src} type="video/mp4" />
    </video>
  );
};

export default DesktopVideo;

import React, { useState } from "react";
import Layout from "../components/layout";
import { StaticImage } from "gatsby-plugin-image";
import OneStop from "../images/svg/onestop.svg";
import Staff, { staffs } from "../components/staff";
import SubTitle from "../components/subtitle";
import { services } from "../components/service";
import { office } from "../components/office";
import Section from "../components/section";
import Hero from "../components/hero";
import { PhoneIcon } from "@heroicons/react/solid";
import Seo from "../components/seo";
import Modal from "../components/modal";
import { graphql } from "gatsby";
import Topics from "../components/topics";

const IndexPage = ({ data }) => {
  const [staffProps, setStaffProps] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal(props) {
    setStaffProps(props);
    setIsOpen(true);
  }
  const topics = data.allMicrocmsTopics.edges;
  return (
    <Layout>
      <Seo />
      <Hero />
      <main>
        {topics && <Topics topics={topics} bg />}
        <Section>
          <div className="-mx-5 md:-mx-10">
            <StaticImage src="../images/allstaffs.png" alt="All Staffs" />
          </div>
          <div className="px-0 md:px-20 leading-8 md:leading-10 text-lg md:text-xl">
            2020年、鈴木会計事務所は 先代の鈴木正光が昭和45年に創業してから、
            <br />
            おかげさまで50年を迎えました。
            <br />
            2020年より、鈴木会計事務所は以下の組織となりました。
          </div>
          <div className="flex flex-col p-5 md:p-10 mx-0 md:mx-20 bg-steel-50 tracking-normal md:tracking-widest leading-8 md:leading-10 text-sm md:text-xl shadow">
            <div className="w-full font-semibold text-lg md:text-2xl mb-2 md:mb-5 pb-1 md:pb-3 border-b border-gray-700">
              鈴木会計事務所
            </div>
            {office.map((o) => (
              <div key={o.id} className="flex">
                <div className="w-3/5">{o.name}</div>
                <div className="w-2/5 text-right">{o.president}</div>
              </div>
            ))}
          </div>
          <div className="px-0 md:px-20 leading-8 md:leading-10 text-lg md:text-xl">
            鈴木会計事務所は 税理士、社会保険労務士、行政書士を中心として、
            <br />
            司法書士や弁護士等、外部の専門家との連携によって、
            <br />
            会計・税務・労務・行政手続きのみならず
            <br />
            経営に役立つ幅広いサービスを提供させて頂く 専門家集団です。
            <br />
            関与するみなさまが明るい未来へ進む お手伝いをさせて頂きます。
          </div>
          <OneStop className="w-full md:w-1/2 m-auto" />
        </Section>
        <Section bg>
          <SubTitle string="代表者ご挨拶" />
          <div
            onClick={() => openModal(staffs[0])}
            onKeyDown={() => openModal(staffs[0])}
            role="button"
            tabIndex={0}
          >
            <div className="text-center">
              <StaticImage
                src="../images/staffs/m-suzuki.jpg"
                className="rounded-full shadow-md w-1/2 md:w-1/3 hover:shadow-inner z-10"
                alt="Suzuki Masaharu"
              />
            </div>
            <div className="text-center mt-3 font-sans text-lg">鈴木 正治</div>
          </div>
          <p className="px-0 md:px-20 leading-8 md:leading-10 text-lg md:text-xl">
            AIにとってかわる職業として税理士が挙げられております。
            <br />
            領収書や通帳から会計処理を会計ソフトに
            <br />
            入力するような作業は減少していくことでしょう。
            <br />
            年末調整や税務申告書の作成自体、
            <br />
            税理士に依頼をしなくても作成ができていくことでしょう。
            <br />
            しかし、業務を定型化を行うための判断やルールの作成で
            <br />
            意思決定するのは人間です。 意思決定や経営はAIではできません。
            <br />
            そういった意味で、会計事務所はAIに任せる業務と、
            <br />
            人にしかできない業務とに分業が進み、 限りある人・時間などを
            <br />
            適材適所に分散させていく時代となっていきます。
            <br />
            私たちは、高齢化・人口減少が進む日本において、
            <br />
            環境の変化に対応していけるように、 ＡＩを活かして業務を効率化させ、
            <br />
            一方でＡＩでは補うことのできない “経営” について
            <br />
            ご相談頂ける存在としてお手伝いをさせて頂きます。
          </p>
        </Section>
        <Section>
          <SubTitle string="選ばれる3つの理由" />
          <div className="grid grid-cols-2">
            <div className="text-xl leading-8 md:text-2xl md:leading-10 p-2 md:p-20 font-sans flex items-center">
              群馬で
              <br />
              50年の実績
            </div>
            <div className="bg-steel-50 p-2 md:p-10 text-sm leading-6 md:text-base md:leading-8">
              「『家と企業の永続繁栄』を実現するため、生きがい，働きがいを求める」
              を企業理念として掲げ、
              中小・中堅企業の力強いパートナーとして地域社会に貢献することを目指して、50年が過ぎました。
            </div>
            <div className="bg-steel-50 p-2 md:p-10 text-sm leading-6 md:text-base md:leading-8">
              弊所では、リスク管理の一環として、AIを利用して、仕訳ゼロ化を目指して、信頼性の高いシステムを通じて支援を行っております。
              同時に、AIはできない経営判断に対するご相談、課題解決のために、一緒になって考えていきましょう！
            </div>
            <div className="text-xl leading-8 md:text-2xl md:leading-10 p-2 md:p-20 font-sans flex items-center">
              AIとの
              <br />
              完全な棲み分け
            </div>
            <div className="text-xl leading-8 md:text-2xl md:leading-10 p-2 md:p-20 font-sans flex items-center">
              海外での豊富な
              <br />
              実務経験
            </div>
            <div className="bg-steel-50 p-2 md:p-10 text-sm leading-6 md:text-base md:leading-8">
              中国で日系企業の会計税務支援を１１年行ってきた経験から、現場を通じて
              しかわからないことがたくさんありました。
              中国バブルを経験した税理士として、そこから学べるものを日本の実務に
              則してご提案させて頂きます。
            </div>
          </div>
        </Section>
        <Section bg>
          <SubTitle string="スタッフ紹介" />
          <div className="grid grid-cols-2 md:grid-cols-3">
            {staffs.map((m) =>
              m.id > 0 ? <Staff key={m.id} {...m} openModal={openModal} /> : ""
            )}
          </div>
          <Modal
            closeModal={closeModal}
            isOpen={isOpen}
            staffProps={staffProps}
          />
        </Section>
        <Section>
          <SubTitle string="業務内容" />
          <div className="p-0 md:px-20 text-base md:text-lg flex flex-col space-y-3">
            {services.map((s) => (
              <div key={s.id} className="flex">
                <div className="w-1/3">{s.name}</div>
                <div className="w-2/3">
                  {s.descriptions.map((d, index) => (
                    <div key={index}>{d}</div>
                  ))}
                </div>
              </div>
            ))}
          </div>
          <div className="flex justify-center mt-5 md:mt-10">
            <a
              href="https://m-suzuki.tkcnf.com/"
              target="_blank"
              rel="noreferrer"
              className="border border-steel-500 hover:bg-steel-50 rounded-sm p-3 font-sans text-steel-500"
            >
              詳しい業務案内はこちら
            </a>
          </div>
        </Section>
        <Section bg>
          <SubTitle string="アクセス" />
          <div className="grid grid-cols-1 md:grid-cols-2">
            <StaticImage alt="Suzuki Building" src="../images/building.jpg" />
            <div className="pl-2 md:pl-10 text-base md:text-lg mt-5 md:mt-0">
              〒371-0013
              <br />
              群馬県前橋市西片貝町5-18-20-3F
              <br />
              <a href="tel:0272434881">
                <div className="flex flex-row font-tel text-xl items-center">
                  <PhoneIcon className="w-5 h-5 mr-1" />
                  027-243-4881
                </div>
              </a>
              <div className="mt-5">
                前橋駅から車で10分。
                <br />
                東部バイパス沿い、セブンイレブンの向かいになります。
              </div>
            </div>
          </div>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3211.8420114241785!2d139.08781131621234!3d36.388818480035454!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x601ef3fa1b9c8d71%3A0x26ce7d678f5cdcda!2z6Yi05pyo5q2j5rK756iO55CG5aOr5LqL5YuZ5omA!5e0!3m2!1sja!2sjp!4v1626145290419!5m2!1sja!2sjp"
            loading="lazy"
            className="w-full h-72 md:h-96 border-steel-500 border-2"
            title="Suzuki Tax Accounting Office Map"
          ></iframe>
        </Section>
      </main>
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query Latest3Topics {
    allMicrocmsTopics(sort: { order: DESC, fields: publishedAt }, limit: 3) {
      edges {
        node {
          topicsId
          publishedAt(formatString: "YYYY-MM-DD")
          title
        }
      }
    }
  }
`;
